import Swiper from 'swiper'
import { Autoplay, Controller, Navigation, Pagination } from 'swiper/modules'
import { register } from 'swiper/element/bundle'

function init() {
  register()


  new Swiper('.broker-slider', {
    modules: [Navigation],
    slidesPerView: 'auto',
    navigation: {
      prevEl: '.broker-slider-prev',
      nextEl: '.broker-slider-next',
    }
  })

}

export default { init }
